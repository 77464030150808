import {
	isBlank
}
from 'voUtils/tools.js';

import {
	favCount, onFavUpdate
}
from 'voUtils/FavStore.js';

export default {

	data: {
		name: undefined,
	},
	computed: {
		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		}

	},

	mounted: function () {
		onFavUpdate(() => {
			this.$forceUpdate();
		});

	},

	methods: {

		navbarToggle: function () {

			$('.main-menu').slideToggle();
			$('.navbar-v1').removeClass('open-by-mob');
		},

		objectSlide: function () {
			$(".header-fastnav-search").toggleClass("show");
			$(".close-btn").removeClass("show");
		},


		toggleSearchbox: function (event) {
			this.showSearchBox = !this.showSearchBox;
			var inputfeld = document.getElementById("objSearch");
			var btn = document.getElementById("objSearchBtn");
			if (this.showSearchBox) {
				this.showTeleBox = false;
				this.showSettings = false;
				btn.tabIndex = 0;
				inputfeld.focus();
			} else {
				inputfeld.blur();
				btn.tabIndex = -1;
			}
		},

		favCount: favCount,
		goSearch: function () {
			if (isBlank(this.name)) {
				window.location.href = 's';
			} else {
				window.location.href = 's#?name=' + encodeURIComponent(this.name);
			}
		}



	}

};